import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ClearIcon from '@mui/icons-material/Clear';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Alert,
  AppBar,
  Badge,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ethers } from 'ethers';
import { ModalContentType, routes } from '../utils/utils';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import blackSpotTokenImage from '../assets/images/BlackSpotToken.png';
import depositChestImage from '../assets/images/DepositChest.png';
import prizeChestImage from '../assets/images/PrizeChest.png';
import { ButtonCustom } from '../components/buttonCustom/ButtonCustom';
import { useChainValues } from '../utils/chain';

import { ChainLogo } from '../components/chainLogo/ChainLogo';

export enum Device {
  Desktop,
  Tablet,
  Smartphone,
}

export const TextFieldCustom = styled(TextField)`
  fieldset {
    border-radius: 25px;
  }
`;

interface Props {
  recepientAddress: string;
  handleOpenModal: any;
  setRecepientAddress: any;
  handleClearClickRecipientAddress: any;
  startDate: Date;
  currentPage: string;
  handleGoToGame: () => void;
  handleGoToLeaderboard: () => void;
}

const Header: React.FC<Props> = ({
  handleOpenModal,
  recepientAddress,
  setRecepientAddress,
  handleClearClickRecipientAddress,
  startDate,
  currentPage,
  handleGoToGame,
  handleGoToLeaderboard,
}) => {
  const location = useLocation();
  // console.log('location is');
  // console.log(location.pathname);

  let navigate = useNavigate();
  const { address, balanceBst, prizeChest, deposit } = useChainValues();

  const handleGoToGamedRoute = () => {
    handleGoToGame();
    navigate(routes.home);
  };

  const handleGoToLeaderboardRoute = () => {
    handleGoToLeaderboard();
    navigate(routes.leaderboard);
  };

  let device: Device = Device.Smartphone;

  let isDesktop = useMediaQuery('(min-width:1024px)');
  let isTablet = useMediaQuery('(min-width:768px)');

  if (isDesktop) {
    device = Device.Desktop;
  } else if (isTablet) {
    device = Device.Tablet;
  } else {
    device = Device.Smartphone;
  }

  const onChangeRecepientAddress = (event: any) => {
    event.preventDefault();
    setRecepientAddress(event.target.value);
  };

  if (!address) {
    return (
      <AppBar
        sx={{
          position: 'absolute',
          backgroundColor: '#FFF',
        }}
        elevation={0}
      >
        <Alert
          severity='warning'
          sx={{
            width: '100%',
            '& .MuiAlert-message': { textAlign: 'center', width: 'inherit' },
          }}
        >
          <span style={{ margin: 20 }}>
            To start playing you will need a Metamask wallet, please make sure
            it is installed correctly{' '}
          </span>
          <span style={{ margin: 20 }}>
            <a target='_blank' href='https://metamask.io/download/'>
              Get Metamask
            </a>
          </span>
        </Alert>
      </AppBar>
    );
  }

  return (
    <AppBar
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        marginTop: 2,
        marginBottom: 2,

        backgroundColor: 'transparent',
      }}
      elevation={0}
    >
      {location.pathname === routes.leaderboard ? (
        <div
          style={{
            display: 'flex',
            position: 'relative',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <div style={{ position: 'absolute', left: 0, height: '100%' }}>
            <ButtonCustom
              sx={{
                backgroundColor: '#774c30',
                height: '100%',
              }}
              variant='contained'
              onClick={handleGoToGamedRoute}
            >
              <ArrowBackIcon />
            </ButtonCustom>
          </div>
          <Typography
            id='modal-modal-title'
            variant='h6'
            sx={{
              position: 'relative',
              color: '#E99A01',
              fontSize: 30,
            }}
          >
            🏆 Leaderboard
          </Typography>
        </div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction={device === Device.Desktop ? 'row' : 'column'}
            sx={{
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            {location.pathname !== routes.leaderboard && (
              <Box
                sx={
                  device === Device.Desktop
                    ? {
                        padding: 2,
                        backgroundColor: '#E0E0E0',
                        width: 'fit-content',
                        flexGrow: 1,
                        borderRadius: '20px',
                      }
                    : {
                        padding: 2,
                        backgroundColor: '#E0E0E0',
                        width: 'fit-content',
                        borderRadius: '20px',
                      }
                }
              >
                <Stack
                  direction='row'
                  flexWrap='wrap'
                  gap={'20px'}
                  height='max-content'
                  justifyContent='start'
                >
                  <Stack direction='row' spacing={2}>
                    <Badge
                      badgeContent={
                        <SwapVertIcon
                          style={{
                            fontSize: '12px',
                          }}
                        />
                      }
                      color='success'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      onClick={() =>
                        handleOpenModal(ModalContentType.ExchangeDoubloon)
                      }
                    >
                      <img
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        src={blackSpotTokenImage}
                        alt='blackSpotTokenImage'
                      />
                    </Badge>

                    <div style={{ display: 'flex' }}>
                      <Typography
                        color='inherit'
                        noWrap
                        sx={{ color: '#202020' }}
                      >
                        {(() => {
                          if (balanceBst.length > 7) {
                            return (
                              <>
                                {balanceBst.length - 7 > 1
                                  ? `${balanceBst.slice(0, 7)} * 10^
                            ${balanceBst.length - 7}`
                                  : `${balanceBst}`}
                              </>
                            );
                          } else return balanceBst;
                        })()}
                      </Typography>
                      <Typography
                        color='inherit'
                        noWrap
                        sx={{ color: '#202020' }}
                      >
                        BS
                      </Typography>
                    </div>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Badge
                      badgeContent={
                        <SwapVertIcon
                          style={{
                            fontSize: '12px',
                          }}
                        />
                      }
                      color='success'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      onClick={() =>
                        handleOpenModal(ModalContentType.ReplenishChest)
                      }
                    >
                      <img
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        src={depositChestImage}
                        alt='depositChestImage'
                      />
                    </Badge>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <Typography
                        color='inherit'
                        noWrap
                        sx={{ color: '#7A5844' }}
                      >
                        {ethers.utils.formatEther(deposit).slice(0, 9)}
                      </Typography>
                      <ChainLogo />
                    </div>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <img
                      style={{
                        width: 20,
                        height: 20,
                      }}
                      src={prizeChestImage}
                      alt='prizeChestImage'
                    />
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <Typography
                        color='inherit'
                        noWrap
                        sx={{ color: '#C4883E' }}
                      >
                        {ethers.utils.formatEther(prizeChest).slice(0, 9)}
                      </Typography>
                      <ChainLogo />
                    </div>
                  </Stack>
                </Stack>
              </Box>
            )}
            {location.pathname === routes.home && (
              <Stack direction='row' gap={1}>
                <TextFieldCustom
                  value={recepientAddress}
                  onChange={onChangeRecepientAddress}
                  inputProps={{ maxLength: 42 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <AccountBalanceWalletIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          sx={{
                            visibility: recepientAddress ? 'visible' : 'hidden',
                          }}
                          onClick={handleClearClickRecipientAddress}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                />
                <ButtonCustom
                  disabled={!ethers.utils.isAddress(recepientAddress)}
                  sx={{ padding: 2, height: '100%' }}
                  variant='contained'
                  endIcon={<MailOutlineIcon />}
                  onClick={() =>
                    handleOpenModal(ModalContentType.TransferToAnother, address)
                  }
                >
                  Send
                </ButtonCustom>
                {location.pathname === routes.home ? (
                  <ButtonCustom
                    sx={{
                      backgroundColor: '#774c30',
                      padding: 2,
                    }}
                    variant='contained'
                    onClick={handleGoToLeaderboardRoute}
                  >
                    <EmojiEventsIcon style={{ height: '100%' }} />
                  </ButtonCustom>
                ) : (
                  <></>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      )}
    </AppBar>
  );
};

export default Header;
