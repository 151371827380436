import { BigNumber, Contract, ethers, Signer } from 'ethers';
import { useContext, useEffect, useMemo } from 'react';
import { abiBlackSpotContract } from '../abi/bsAbi';
import { abiDoubloonContract } from '../abi/dblAbi';
import { ContractContext } from '../App';
import { useSocketListeners } from './socket';

export const connectMetamask = async (
  blackSpot: {
    contractAddress: string | undefined;
    setContract: React.Dispatch<
      React.SetStateAction<ethers.Contract | undefined>
    >;
  },
  doubloon: {
    contractAddress: string | undefined;
    setContract: React.Dispatch<
      React.SetStateAction<ethers.Contract | undefined>
    >;
  }
) => {
  // console.log({
  //   bs: blackSpot.contractAddress,
  //   db: doubloon.contractAddress,
  // });

  if (
    window.ethereum?.isMetaMask &&
    blackSpot.contractAddress &&
    doubloon.contractAddress
  ) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // check if metamask is installed and connected
    await provider.send('eth_requestAccounts', []);

    // console.log({ provider });
    //  'accounts' is assigned a value but never used
    // const accounts = await window.ethereum.request!({
    //   method: "eth_requestAccounts",
    // });
    const signer = provider.getSigner();
    // console.log({ signer });
    (provider.provider as any).on('chainChanged', () => {
      window.location.reload();
    });

    const bs = new ethers.Contract(
      blackSpot.contractAddress,
      abiBlackSpotContract,
      signer
    );
    blackSpot.setContract(bs);

    const dbl = new ethers.Contract(
      doubloon.contractAddress,
      abiDoubloonContract,
      signer
    );
    doubloon.setContract(dbl);

    return provider;
  } else {
    // alert('Please install Mask');
  }
};
const methodsErc20 = {
  _balanceOf: async (
    address: string,
    contract?: Contract
  ): Promise<string | undefined> => {
    const balance = await contract?.balanceOf(address);
    return balance?.toString();
  },
  _transfer: async (recipient: string, amount: string, contract?: Contract) => {
    await contract?.transfer(recipient, amount, {
      // gasPrice: ethers.utils.parseUnits("100", "gwei"),
      // gasLimit: 1000000,
    });
  },
  _burn: async (amount: string, contract?: Contract) => {
    // console.log(amount);

    contract?.burn(amount);
  },
};
const methodsBst = {
  _getPrizeChest: async (contract?: Contract): Promise<string | undefined> => {
    return (await contract?.getPrizeChest())?.toString();
  },

  _getDeposit: async (contract?: Contract): Promise<string | undefined> => {
    return (await contract?.['getDeposit()']())?.toString();
  },

  _getFinishingTransactions: async (
    contract?: Contract
  ): Promise<string | undefined> => {
    console.log(
      'await contract?.getFinishingTransactions()',
      contract,
      await contract?.getFinishingTransactions()
    );
    const txNumber = await contract?.getFinishingTransactions();

    return txNumber ? BigNumber.from(txNumber).toString() : '100';
  },

  _transferETH: async (
    signer: Signer | undefined,
    to: string | undefined,
    amount: string
  ) => {
    if (!signer) throw new Error('Signer is undefined');
    if (!to) throw new Error('Target address is undefined');

    await signer.sendTransaction({
      to: to,
      value: ethers.utils.parseEther(amount),
    });
  },
  _withdraw: async (amount: string, contract?: Contract) => {
    contract?.withdraw(ethers.utils.parseEther(amount));
  },
};

// const methodsDoubloon = {};
const listeners = {
  _onDepositChange: async (
    callback: () => any,
    contract: Contract | undefined
  ) => {
    if (!contract) return;

    const address = await contract?.signer.getAddress();
    const filterDeposit = contract?.filters.Deposit(address);
    const filterWithdraw = contract?.filters.Withdraw(address);
    const filterTransfer = contract?.filters.GameTransaction(null, address);
    const filterWinner = contract?.filters.Winner(null, address);
    // TODO: проверить перерисовку
    // console.log({
    //   filterDeposit,
    //   filterWithdraw,
    //   filterTransfer,
    //   filterWinner,
    // });

    if (!filterDeposit || !filterWithdraw || !filterTransfer || !filterWinner)
      throw new Error('DepositChange filter error');

    contract?.on(filterDeposit, () => callback());
    contract?.on(filterWithdraw, () => callback());
    contract?.on(filterTransfer, () => callback());
    contract?.on(filterWinner, () => callback());
  },
};

export const useChainMethods = () => {
  const blackSpot = useContext(ContractContext).blackSpot;
  const doubloon = useContext(ContractContext).doubloon;

  return useMemo(() => {
    console.log('ВЫЗОВ');

    return {
      transferBst: (to: string, amount: string) => {
        methodsErc20._transfer(to, amount, blackSpot);
      },
      transferDoubloon: (to: string, amount: string) => {
        methodsErc20._transfer(to, amount, doubloon);
      },
      burnBst: (amount: string) => {
        console.log('lol');
        methodsErc20._burn(amount, blackSpot);
      },
      burnDoubloon: (amount: string) => {
        methodsErc20._burn(
          ethers.utils.parseUnits(amount, '8').toString(),
          doubloon
        );
      },
      getPrizeChest: () => methodsBst._getPrizeChest(blackSpot),
      getDeposit: () => methodsBst._getDeposit(blackSpot),
      getFinishTransactions: () =>
        methodsBst._getFinishingTransactions(blackSpot),
      bstBalanceOf: (address: string) =>
        methodsErc20._balanceOf(address, blackSpot),
      getSigner: () => blackSpot?.signer!,
      getAddress: async () => {
        return await blackSpot?.signer.getAddress();
      },
      transferETH: (to: string, amount: string) =>
        methodsBst._transferETH(blackSpot?.signer, to, amount),
      depositETH: (amount: string) =>
        methodsBst._transferETH(blackSpot?.signer, blackSpot?.address, amount),
      withdraw: (amount: string) => methodsBst._withdraw(amount, blackSpot),

      doubloonBalanceOf: (address: string) =>
        methodsErc20._balanceOf(address, doubloon),
    };
  }, [blackSpot, doubloon]);
};

export const useChainListeners = () => {
  const blackSpot = useContext(ContractContext).blackSpot;
  // TODO: посмотреть перезапись
  return {
    onDepositChange: (callback: () => any) =>
      listeners._onDepositChange(callback, blackSpot),
  };
};
export const useChainValues = () => {
  const blackSpot = useContext(ContractContext).blackSpot;
  const doubloon = useContext(ContractContext).doubloon;
  const setValues = useContext(ContractContext).setValues;
  const updateValues = (data: Partial<ChainValues>) => {
    setValues?.((prev) => ({ ...prev, ...data }));
  };
  const {
    getDeposit,
    getPrizeChest,
    getAddress,
    getFinishTransactions,
    getSigner,
    bstBalanceOf,
    doubloonBalanceOf,
  } = useChainMethods();
  const { onDepositChange: onDeposit } = useChainListeners();
  const { onGameTransaction } = useSocketListeners();
  useEffect(() => {
    onDeposit(async () => {
      updateValues({ deposit: (await getDeposit()) ?? '0' });
    });
    (async () => {
      updateValues({ deposit: (await getDeposit()) ?? '0' });
    })();
    (async () => {
      updateValues({ prizeChest: (await getPrizeChest()) ?? '0' });
    })();
    (async () => {
      updateValues({
        finishTransactions: (await getFinishTransactions()) ?? '0',
      });
    })();
    onGameTransaction(async () => {
      console.log({
        onGameTransaction: await getFinishTransactions(),
      });

      updateValues({
        finishTransactions: (await getFinishTransactions()) ?? '0',
        prizeChest: (await getPrizeChest()) ?? '0',
      });
    });
    (async () => {
      updateValues({
        address: (await getAddress()) ?? '0x',
      });
    })();
    (async () => {
      updateValues({
        balanceBst: (await bstBalanceOf((await getAddress()) ?? '0x')) ?? '0',
      });
    })();
    (async () => {
      updateValues({
        balanceDoubloon:
          (await doubloonBalanceOf((await getAddress()) ?? '0x')) ?? '0',
      });
    })();
    updateValues({ signer: getSigner() });
  }, [doubloon, blackSpot]);

  console.log({ chvals: { ...useContext(ContractContext).values } });

  return {
    ...useContext(ContractContext).values,
  };
};
